import { useEffect } from "react";
import { useState } from "react";
import InputRange from "react-input-range";
import { useSelector, useDispatch } from "react-redux";
import { addPrice } from "../../features/properties/propertiesSlice";
import { setPrice, resetFilters } from "../../features/search/searchSlice";
import { BeatLoader } from "react-spinners";

import formatPrice from "../../utils/formatPrice";

/**
 * Price range slider
 * @returns
 */
const RangeSlider = () => {
  const { price } = useSelector((state) => state.search);
  const [priceValue, setPriceValue] = useState(price);

  const [maxPrice, setMaxPrice] = useState(50000000);
  const [maxPriceLoading, setMaxPriceLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // get max price of listings from API
    const getMaxPrice = async () => {
      dispatch(setPrice({ min: 50000, max: 50000000 }));
      setMaxPriceLoading(true);

      const maxPriceRes = await fetch(
        `${process.env.SLS_URL}/listing/max-price`,
        {
          method: "GET",
        }
      );

      const maxPriceJson = await maxPriceRes.json();

      if (maxPriceJson && "maxPrice" in maxPriceJson) {
        setMaxPrice(maxPriceJson.maxPrice);

        // set slider value as maxPrice + 1 to display "unlimited" text on slider
        dispatch(setPrice({ min: 50000, max: maxPriceJson.maxPrice + 100 }));
        setPriceValue({ min: 50000, max: maxPriceJson.maxPrice + 100 });
      }

      try {
      } catch (e) {
        console.log(e);

        dispatch(setPrice({ min: 50000, max: 50000000 }));
      }

      setMaxPriceLoading(false);
    };

    getMaxPrice();
  }, []);

  const handleOnChange = (value) => {
    // setPriceValue({ min: value.min > 0 ? value.min : 50000, max: value.max });
    setPriceValue({
      min: value.min > 0 ? value.min : 50000,
      max: value.max,
    });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(
        setPrice({
          min: priceValue.min,
          max: priceValue.max,
        })
      );
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [priceValue]);

  return maxPriceLoading ? (
    <BeatLoader
      loading={maxPriceLoading}
      color={"white"}
      aria-label="Loading Spinner"
      data-testid="loader"
      size={10}
    />
  ) : (
    <div className="nft__filter-price tp-range-slider tp-range-slider-dark mb-20">
      <div className="nft__filter-price-inner d-flex align-items-center justify-content-between">
        <div className="nft__filter-price-box">
          <div className="d-flex align-items-center">
            <span>{formatPrice(priceValue.min)}</span>
          </div>
        </div>
        <div className="nft__filter-price-box">
          <div className="d-flex align-items-center">
            <span>
              {priceValue.max > maxPrice
                ? "Unlimited"
                : formatPrice(priceValue.max)}
            </span>
          </div>
        </div>
      </div>

      <InputRange
        formatLabel={(value) => ``}
        maxValue={maxPrice}
        minValue={50000}
        value={priceValue}
        onChange={(value) => {
          handleOnChange(value);
        }}
      />

      <div className="slider-styled inside-slider" id="nft-slider"></div>
    </div>
  );
};

export default RangeSlider;
